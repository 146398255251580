import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { PeriodType } from './mytype/period';
import nikkeiHeader from './resources/images/nikkei-header.png'
import graph from './resources/images/graph.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLineChart, faArrowAltCircleUp, faArrowAltCircleDown, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { services } from './services/services';

const nikkeiMorningAnnounceDate = moment().format('YYYY-MM-DD 09:30:00');
const nikkeiEveningAnnounceDate = moment().format('YYYY-MM-DD 13:25:00');

const App = () => {
  const [time, setTime] = useState(new Date());
  const [positiveOrNegative, setPositiveOrNegative] = useState("+");
  const [upNumber, setUpNumber] = useState(46000.00);
  const [downNumber, setDownNumber] = useState(0.00);
  const [changedPercent, setChangedPercent] = useState(0.00);
  const [nikkeiMorningStat, setNikkeiMorningStat] = useState<PeriodType[]>([]);
  const [nikkeiEveningStat, setNikkeiEveningStat] = useState<PeriodType[]>([]);
  const [nikkeiMorningCurrent, setNikkeiMorningCurrent] = useState<PeriodType | null>(null);
  const [nikkeiEveningCurrent, setNikkeiEveningCurrent] = useState<PeriodType | null>(null);

  const getNikkeiMorningStatData = useCallback(async () => {
    const response = await services.getStatistics(12);
    setNikkeiMorningStat(response.data);
  }, []);

  const getNikkeiEveningStatData = useCallback(async () => {
    const response = await services.getStatistics(13);
    setNikkeiEveningStat(response.data);
  }, []);

  useEffect(() => {

    getNikkeiMorningStatData();
    getNikkeiEveningStatData();

    nikkeiMorningData();
    nikkeiEveningData();

    // every 5 min
    triggerData();
    const interval = setInterval(() => {
      triggerData();
    }, 5 * (60 * 1000));

    const intervalForShow = setInterval(() => {
      if (moment().toDate().getTime() === moment(nikkeiMorningAnnounceDate).toDate().getTime()) {
        triggerData();
      }

      if (moment().toDate().getTime() === moment(nikkeiEveningAnnounceDate).toDate().getTime()) {
        triggerData();
      }
    }, (60 * 1000));

    return () => {
      clearInterval(interval);
      clearInterval(intervalForShow)
    }
  }, [getNikkeiMorningStatData, getNikkeiEveningStatData]);

  const triggerData = async () => {

    if (
      moment().toDate().getTime() >= moment(nikkeiMorningAnnounceDate).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format("YYYY-MM-DD 13:00:00")).toDate().getTime()
    ) {

      // show release
      if (!nikkeiMorningCurrent) {
        const currentNikkeiMorning = await services.getCurrentPeriods(12);
        const data: PeriodType = currentNikkeiMorning.data;
        const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const precisionChangedPercent = 100; // 2 decimals
        const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

        setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
        setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
        return setNikkeiMorningCurrent(currentNikkeiMorning.data);
      }

      const num = parseInt(upNumber.toString().substring(0, 4) + nikkeiMorningCurrent.up3) * 0.01;
      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const precisionChangedPercent = 100; // 2 decimals
      const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

      setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (nikkeiMorningCurrent.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
      setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
      return setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));

    }

    if (
      moment().toDate().getTime() >= moment(nikkeiEveningAnnounceDate).toDate().getTime() &&
      moment().toDate().getTime() <= moment(moment().format("YYYY-MM-DD 23:59:59")).toDate().getTime()
    ) {
      // show release
      if (!nikkeiEveningCurrent) {
        const currentNikkeiEvening = await services.getCurrentPeriods(13);
        const data: PeriodType = currentNikkeiEvening.data;
        const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const precisionChangedPercent = 100; // 2 decimals
        const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

        setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
        return setNikkeiEveningCurrent(currentNikkeiEvening.data);
      }

      const num = parseInt(upNumber.toString().substring(0, 4) + nikkeiEveningCurrent.up3) * 0.01;
      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const precisionChangedPercent = 100; // 2 decimals
      const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

      setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (nikkeiEveningCurrent.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
      setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
      return setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
    }

    if (moment().toDate().getTime() < moment(nikkeiMorningAnnounceDate).toDate().getTime()) {
      return randomNumber();
    }

    if (
      moment().toDate().getTime() >= moment(moment().format("YYYY-MM-DD 13:00:00")).toDate().getTime()
      && moment().toDate().getTime() < moment(nikkeiEveningAnnounceDate).toDate().getTime()
    ) {
      return randomNumber();
    }
  }

  const randomNumber = () => {
    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    const precisionUp = 100; // 2 decimals
    const numUp = Math.floor(Math.random() * (100 * precisionUp - 1 * precisionUp) + 1 * precisionUp) / (1 * precisionUp);

    const precisionDown = 100; // 2 decimals
    const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

    const precisionChangedPercent = 100; // 2 decimals
    const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

    setUpNumber(upNumber + (numUp * positiveOrNegativeNumber));
    setDownNumber(numDown * positiveOrNegativeNumber);
    setChangedPercent(numChangedPercent * positiveOrNegativeNumber);
    setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
  }

  const renderStat = () => {
    if (nikkeiEveningStat.length === 0 || nikkeiEveningStat.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      )
    }

    const stat = [];

    for (let i = 0; i < nikkeiMorningStat.length; i++) {
      const element = (
        <tr>
          <td>{moment(nikkeiMorningStat[i].dateAnnounce).format("DD/MM/YYYY")}</td>
          <td>{nikkeiMorningStat[i].up3}</td>
          <td>{nikkeiMorningStat[i].down2}</td>
          <td>{nikkeiEveningStat[i].up3}</td>
          <td>{nikkeiEveningStat[i].down2}</td>
        </tr>
      );

      stat.push(element);
    }

    return stat;
  }

  const nikkeiMorningData = async () => {
    if (moment().toDate().getTime() < moment(nikkeiMorningAnnounceDate).toDate().getTime()) {
      // show null
      return setNikkeiMorningCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(nikkeiMorningAnnounceDate).toDate().getTime()) {
      // show release
      if (!nikkeiMorningCurrent) {
        const currentNikkeiMorning = await services.getCurrentPeriods(12);
        return setNikkeiMorningCurrent(currentNikkeiMorning.data);
      }
    }
  }

  const nikkeiEveningData = async () => {
    if (moment().toDate().getTime() < moment(nikkeiEveningAnnounceDate).toDate().getTime()) {
      // show null
      return setNikkeiEveningCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(nikkeiEveningAnnounceDate).toDate().getTime()) {
      // show release
      if (!nikkeiEveningCurrent) {
        const currentNikkeiEvening = await services.getCurrentPeriods(13);
        return setNikkeiEveningCurrent(currentNikkeiEvening.data);
      }
    }
  }

  const morningClose = () => {
    // if (!nikkeiMorningCurrent) {
    //   return (
    //     <>
    //       <td>Monring Closed</td>
    //       <td>Up</td>
    //       <td></td>
    //       <td>Down</td>
    //       <td></td>
    //     </>
    //   )
    // }
    return (
      <>
        <td>Monring Closed</td>
        <td>Up</td>
        <td>{nikkeiMorningCurrent?.up3}</td>
        <td>Down</td>
        <td>{nikkeiMorningCurrent?.down2}</td>
      </>
    )
  }

  const eveningClose = () => {
    // if (!nikkeiMorningCurrent) {
    //   return (
    //     <>
    //       <td>Monring Closed</td>
    //       <td>Up</td>
    //       <td></td>
    //       <td>Down</td>
    //       <td></td>
    //     </>
    //   )
    // }
    return (
      <>
        <td>Evening Closed</td>
        <td>Up</td>
        <td>{nikkeiEveningCurrent?.up3}</td>
        <td>Down</td>
        <td>{nikkeiEveningCurrent?.down2}</td>
      </>
    )
  }

  const announceClose = () => {
    if (
      moment().toDate().getTime() >= moment(nikkeiMorningAnnounceDate).toDate().getTime()
      && moment().toDate().getTime() < moment(nikkeiEveningAnnounceDate).toDate().getTime()
    ) {
      return "*Morning Closed"
    }

    if (
      moment().toDate().getTime() >= moment(nikkeiEveningAnnounceDate).toDate().getTime()
      && moment().toDate().getTime() < moment(moment().format("YYYY-MM-DD 23:59:59")).toDate().getTime()
    ) {
      return "*Evening Closed"
    }

    return "";
  }

  return (
    <div className="container p-0">
      <div className="nikkei-index">
        <div className="nikkei-header p-2">
          <img src={nikkeiHeader} />
        </div>
        <div className="nav d-flex py-0" style={{ backgroundColor: 'white' }}>
          <div className="navbar-brand d-flex">
            <div className="navbar-brand-top">Nikkei Indexes</div>
            <div className="navbar-brand-subtop">-Nikkei 225 turns 70 years old-</div>
          </div>
          <div className="navbar-right">
            <ul className="mb-0 pl-0">
              <li className="language-link active">
                <a href="#">English</a>
              </li>
              <li className="language-link">
                <a href="#">簡体中文</a>
              </li>
              <li className="language-link">
                <a href="#">日本語</a>
              </li>
              <li className="members-link">
                <a href="#">For Subscribers</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="nikkei-content">
          <div className="row">
            <div className="col-xs-12 col-sm-3 d-flex" style={{ alignItems: 'center' }}>
              <div className="l-panel">
                <div className="top-nk225-text-nk">
                  Nikkei 225
                </div>
                <div className="top-nk225-text">
                  <span>
                    <div id="datedtime_nkave">
                      {moment().format("MMM/DD/YYYY")} {announceClose()}
                    </div>
                  </span>
                </div>
                <div className="top-nk225-text-link">
                  <FontAwesomeIcon icon={faLineChart} /> &nbsp;<a href="#" className="right">Intraday Chart</a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-7">
              <div className="align-baseline d-flex p-lg-2 col-12">
                <span style={{ fontSize: 20 }}>{(upNumber).toFixed(2)}</span> <span className="pl-2 pr-2">{positiveOrNegative}{Math.abs(downNumber).toFixed(2)} ({positiveOrNegative}{Math.abs(changedPercent).toFixed(2)}%)</span> {positiveOrNegative === "+" ? <FontAwesomeIcon icon={faArrowAltCircleUp} /> : <FontAwesomeIcon icon={faArrowAltCircleDown} />}
              </div>
              <div className="nikkei-chart">
                <img src={graph} width="100%" height={200} />
              </div>
              <div className="nikkei-today">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      {morningClose()}
                    </tr>
                    <tr>
                      {eveningClose()}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 d-flex">
              <div className="r-panel d-flex">
                <div className="top-nk225-menu">
                  <a href="#"><FontAwesomeIcon icon={faCircleArrowRight} />&nbsp;More Details</a>
                </div>
                <div className="top-nk225-menu pt-1"><a href="#">
                  <FontAwesomeIcon icon={faCircleArrowRight} />&nbsp;Daily Summary</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nikkei-stat">
        <h4>Statistics</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th rowSpan={2}>Date</th>
              <th colSpan={2}>Morning</th>
              <th colSpan={2}>Evening</th>
            </tr>
            <tr>
              <th>Up</th>
              <th>Down</th>
              <th>Up</th>
              <th>Down</th>
            </tr>
          </thead>
          <tbody>
            {renderStat()}
          </tbody>
        </table>
      </div>
      {/* <div className="footer">
        <div className="nikkei-footer" >
          <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iNTciIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCA1NyAxMiI+PGRlZnM+PHN0eWxlPi5jbHMtMiB7IGZpbGw6ICNmZmZmZmY7IH08L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNNTMuNCAxMS45MzRoLTMuMzUyTDUzLjYzNS4wMDRoMy4zNTVsLTMuNTkgMTEuOTN6TTQ2LjExIDIuMDRsLS44MTIgMi43MDVoNC45bC0uNjE3IDIuMDQzaC00Ljg5NWwtLjkgMi45ODdoNS4zNjRsLS42NjcgMi4xNmgtMTEuMDlsLTIuMTgzLTYuNjZoLTEuMjhsLTIuMDAyIDYuNjZIMjYuMWwtMi4xOC02LjY2aC0xLjI4M2wtMS45OTggNi42NmgtMy4zNTNMMjAuODcyLjAwNWgzLjM1MmwtMS41MyA1LjA3N0wyNy41My4wMDJoMy45MTZMMjYuMDYgNS4yNzdoMS4xNmwxLjg3IDQuOTY0TDMyLjE2Mi4wMDRoMy4zNTRsLTEuNTMgNS4wOEwzOC44Mi4wMDRoMy45MmwtNS4zOTMgNS4yNzJoMS4xNjNsMS44MiA0LjgzN0w0My4zNyAwaDguNDZsLS42MTUgMi4wNEg0Ni4xMXptLTMzLjg0IDkuODk0TDE1Ljg2LjAwNGgzLjM1bC0zLjU4NiAxMS45M2gtMy4zNXptLTUuMzY2IDBsLTEuNi04LTIuNDA2IDhILS4wMUwzLjU3OC4wMDRoMy44NGwxLjU3NiA3Ljg1OEwxMS4zNTYuMDA0aDIuOTA0bC0zLjU4NCAxMS45M0g2LjkwNHoiIGlkPSJwYXRoLTEiIGNsYXNzPSJjbHMtMiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+" title="NIKKEI" alt="NIKKEI" /> <span>Nikkei Inc. No reproduction without permission.</span>
        </div>
      </div> */}
    </div>
  )
}

export default App;